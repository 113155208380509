import React from "react";
import { MainBody } from "components/templates";

const Whatsapp = () => {
  return (
    <MainBody>
      <h1>Whatsapp</h1>
      <div className="mt-10">

      </div>
    </MainBody>
  );
};

export default Whatsapp;
